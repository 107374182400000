/* eslint-disable react/no-unused-state */
/* eslint-disable no-alert */
/* eslint-disable react/static-property-placement */
import React from 'react';

import { MyContext } from '../Context';
import history from '../history';
import MiddleBlock from './components/MiddleBlock';
import RegisterForm from '../components/RegisterForm';

class Register extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    this.state = {
      plantType:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined
          ? context.state.plantType
          : '',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to the top of the page
    if (this.context !== undefined) {
      const { state, setPath } = this.context;
      // go to errorPage if the context.state is undefined
      if (
        state !== undefined &&
        state.path !== undefined &&
        state.path !== '/plant' &&
        state.path !== '/register_on' &&
        state.path !== '/plant_on' &&
        state.location !== [] &&
        !state.test
      ) {
        history.push('/code');
      } else if (
        state === undefined ||
        setPath === undefined ||
        state.path === undefined ||
        state.forestId === undefined ||
        state.location[0] === undefined ||
        state.location[1] === undefined ||
        state.plantType === undefined
      ) {
        history.push('/errorgc');
      } else {
        setPath('/register_on');
      }
    } else {
      // go to errorPage if the context is undefined
      history.push('/errorgc');
    }
  }

  render() {
    const { plantType } = this.state;
    const treeName = plantType.name;
    const treeDescr = plantType.description;
    return (
      <MiddleBlock page="register">
        <p className="title center_text" id="registerTitle">
          {treeName}
          <br />
        </p>
        <p className="subtitle center_text italic_text">{treeDescr}</p>

        <RegisterForm widget="giftCode" />

        <p className="extra_info hor_center" id="agreeInfo">
          You will receive a confirmation of your tree by email. By confirming
          you agree to the Treemendo Ltd{' '}
          <a
            href="https://www.treemendo.com/terms-of-service/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>{' '}
          and{' '}
          <a
            href="https://www.treemendo.com/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </p>
      </MiddleBlock>
    );
  }
}

export default Register;
