import React from 'react';
import confirm from './Backgrounds/Desktop/confirm_compensate_back.png';
import confirmMobile from './Backgrounds/Mobile/confirm_compensate_back_mobile.png';
import treemendoLogo from './Assets/logo_treemendo_beta.png';
import RegisterForm from '../components/RegisterForm';
import CarbonTemplate from './components/CarbonTemplate';
import CarbonBlockGrid from './components/CarbonBlockGrid';
import ProgressBar from '../components/ProgressBar';
import Arrows from './components/Arrows';
import EmissionResult from './components/EmissionResult';
import AgreeInfo from './components/AgreeInfo';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      currentId,
      amountPages,
      percentage,
      amountTrees,
      previousItem,
      nextItem,
      exchangeRate,
      formatter,
      basePrice,
    } = this.props;
    return (
      <CarbonTemplate imageMobile={confirmMobile} image={confirm}>
        <Arrows
          currentId={currentId}
          carbonItemsLength={currentId - 2}
          amountPages={amountPages}
          prevItem={previousItem}
        />

        <div className="carbon_item_container hor_center">
          <ProgressBar curId={currentId} nrItems={amountPages} />
          <CarbonBlockGrid
            currentId={currentId}
            previousItem={previousItem}
            carbonItemsLength={currentId - 2}
            page="register"
            label="Register"
          >
            <EmissionResult
              percentage={percentage}
              amountTrees={amountTrees}
              formatter={formatter}
              basePrice={basePrice}
              exchangeRate={exchangeRate}
            />
            <RegisterForm nextItem={nextItem} widget="carbon" />
            <div>
              <div>
                <img
                  src={treemendoLogo}
                  alt="logo"
                  className="treemendo_logo"
                />
              </div>
              <AgreeInfo />
            </div>
          </CarbonBlockGrid>
        </div>
      </CarbonTemplate>
    );
  }
}

export default RegisterPage;
