/* eslint-disable react/no-did-update-set-state */
import React, { Component } from 'react';
import './progressBar.css';

export class ProgressBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentID: this.getCorrId(),
    };
  }

  componentDidUpdate() {
    // only update currentID if it is needed, ensuring it will not keep updating infinitely)
    const { curId } = this.props;
    const { currentID } = this.state;
    if (curId !== undefined && currentID !== curId)
      this.setState({
        currentID: this.getCorrId(),
      });
  }

  getCorrId = () => {
    const { curId, page } = this.props;
    let tmpId = 0;
    if (curId !== undefined) tmpId = curId;
    else if (page !== undefined) tmpId = this.currentId();
    return tmpId;
  };

  currentId = () => {
    const { page } = this.props;
    switch (page) {
      case 'code':
        return 1;
      case 'validated':
        return 2;
      case 'planting':
        return 3;
      case 'register':
        return 4;
      case 'final':
        return 5;
      case 'errorgc':
        return -1;
      default:
        return 0;
    }
  };

  render() {
    const { nrItems } = this.props;
    const { currentID } = this.state;
    return (
      <div
        id="progBarContainer"
        style={{ gridTemplateColumns: `repeat(${nrItems}, 1fr)` }}
      >
        {(() => {
          const items = [];
          if (currentID !== -1) {
            for (let i = 1; i < nrItems + 1; i += 1) {
              items.push(
                <div
                  className="item"
                  key={i}
                  style={{
                    backgroundColor: i <= currentID ? '#79b172' : 'LightGray',
                  }}
                >
                  <p />
                </div>,
              );
            }
          }
          return items;
        })()}
      </div>
    );
  }
}

export default ProgressBar;
