/* eslint-disable react/no-unused-state */
/* eslint-disable no-alert */
/* eslint-disable react/static-property-placement */
import React from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import * as devDetect from 'react-device-detect';

import shareImg from '../pictures/share.png';
import facebookImg from '../pictures/fbook.png';
import mailImg from '../pictures/mailBtn.png';
import linkedinImg from '../pictures/lin.png';
import twitterImg from '../pictures/Twitter.png';

import { MyContext } from '../Context';

import './shareMenu.css';

class ShareMenu extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    if (props.widget === 'giftCode') {
      this.state = {
        shareData: {
          title: 'Treemendo',
          text:
            context !== undefined &&
            context.state !== undefined &&
            context.state.plantType !== undefined &&
            context.state.plantType.name !== undefined
              ? `I have just planted ${
                  /[aeiouAEIOU]/.test(context.state.plantType.name.charAt(0))
                    ? 'an'
                    : 'a'
                } ${
                  context.state.plantType.name
                } via Treemendo! Plant a tree too!\n`
              : '',
          url: 'https://treemendo.com',
        },
        isMobileDevice:
          devDetect.isAndroid || devDetect.isIOS || devDetect.isIOS13,
        supportsNativeShare: navigator.share !== undefined,
      };
    } else {
      const { amountTrees, percentage } = this.props;
      this.state = {
        shareData: {
          title: 'Treemendo',
          text: `I have just planted ${amountTrees} trees via Treemendo to compensate ${percentage}% of my emissions.\nCheck out your emissions and compensate too!\n`,
          url: 'https://app.treemendo.com/carbon',
        },
        isMobileDevice:
          devDetect.isAndroid || devDetect.isIOS || devDetect.isIOS13,
        supportsNativeShare: navigator.share !== undefined,
      };
    }
  }

  shareButtonClickHandler = () => {
    const { supportsNativeShare } = this.state;
    if (supportsNativeShare) {
      const { shareData } = this.state;
      navigator.share(shareData);
    }
  };

  nativeShareButton = () => {
    const { supportsNativeShare, isMobileDevice } = this.state;
    const { widget } = this.props;
    if (supportsNativeShare && isMobileDevice && widget === 'giftCode') {
      return (
        <button
          type="button"
          id="shareButtonGift"
          onClick={this.shareButtonClickHandler}
        >
          <img id="shareImg" alt="Share on Social Media!" src={shareImg} />
        </button>
      );
    }
    if (supportsNativeShare && isMobileDevice && widget === 'carbon') {
      return (
        <div id="nativeShareTextCarbon">
          <button
            type="button"
            id="shareButtonCarbon"
            onClick={this.shareButtonClickHandler}
          >
            <img id="shareImg" alt="Share on Social Media!" src={shareImg} />
          </button>
          {this.widgetSpecificShareText()}
        </div>
      );
    }
    return null;
  };

  widgetSpecificShareText = () => {
    const { widget } = this.props;
    if (widget === 'giftCode') {
      return (
        <p className="subtitle center_text italic_text" id="shareText">
          Share your tree with friends!
        </p>
      );
    }
    return (
      <p className="subtitle center_text italic_text" id="shareText">
        Double your impact by <br />
        inviting your friends!
      </p>
    );
  };

  // If native sharing is not supported four share buttons (mail and social media) will be shown
  // If not the text will have to be adjusted for it.
  render() {
    const { shareData, supportsNativeShare, isMobileDevice } = this.state;
    const { widget } = this.props;
    if (!supportsNativeShare || !isMobileDevice) {
      return (
        <div id="socialMediaBlock">
          <div id="socialButtons">
            <FacebookShareButton
              id="facebookShare"
              url={shareData.url}
              quote={shareData.text}
              hashtag="#treemendo"
            >
              <img
                id="socialButtons"
                alt="Share to Facebook"
                src={facebookImg}
              />
            </FacebookShareButton>
            <EmailShareButton
              id="emailShare"
              url={shareData.url}
              subject="I just planted a tree"
              body={shareData.text}
              separator=""
            >
              <img id="socialButtons" alt="Share by Email" src={mailImg} />
            </EmailShareButton>
            <LinkedinShareButton
              id="linkedinShare"
              url={shareData.url}
              title="Treemendo"
              summary={shareData.text}
            >
              <img
                id="socialButtons"
                alt="Share to LinkedIn"
                src={linkedinImg}
              />
            </LinkedinShareButton>
            <TwitterShareButton
              id="twitterShare"
              url={shareData.url}
              title={shareData.text}
            >
              <img id="socialButtons" alt="Share to Twitter" src={twitterImg} />
            </TwitterShareButton>
          </div>
          {/* change this text for carbon */}
          {this.widgetSpecificShareText()}
        </div>
      );
    }
    if (widget === 'giftCode') {
      return (
        <p
          className="subtitle center_text italic_text"
          id="nativeShareTextGiftCode"
        >
          Track the growth and health of your tree!
        </p>
      );
    }
    return <div>{this.nativeShareButton()}</div>;
  }
}

export default ShareMenu;
