/* eslint-disable global-require */
import React, { Component } from 'react';
import './middleBlock.css';
import ProgressBar from '../../components/ProgressBar';

class MiddleBlock extends Component {
  componentDidMount = () => {
    const { page } = this.props;
    // eslint-disable-next-line import/no-dynamic-require
    if (page !== undefined) require(`./../${page}.css`);
  };

  render() {
    const { page, title, children } = this.props;
    return (
      <div className="main_container background">
        <div className="block middle_block">
          <ProgressBar page={page} nrItems={5} />
          {title === undefined ? null : (
            <p className="title center_text">{title}</p>
          )}
          {children}
        </div>
      </div>
    );
  }
}

export default MiddleBlock;
