import axios from 'axios';

const data = {
  NL: {
    hoursPublicTransport: 1.1585,
    distanceDriven: 250,
    hoursPlane: 5.6,
    portionsMeatCheese: 3.62119863,
    moneyElectricity: 113,
    moneyGas: 54,
    distanceDrivenCO2: 0.119,
    moneyElectricityKwh: 0.15360983103,
    kwhElectricityCO2: 0.316,
    moneyGasKwh: 7.69230769231,
    currencyName: 'euro',
    distanceUnit: 'kilometres',
  },
  UK: {
    hoursPublicTransport: 1.3846,
    distanceDriven: 220.79,
    hoursPlane: 4.038,
    portionsMeatCheese: 3.027534247,
    moneyElectricity: 56.33,
    moneyGas: 58.25,
    distanceDrivenCO2: 0.3925,
    moneyElectricityKwh: 0.0946969697,
    kwhElectricityCO2: 0.163,
    moneyGasKwh: 5.55555555556,
    currencyName: 'pound',
    distanceUnit: 'kilometres',
  },
  USA: {
    hoursPublicTransport: 0.332057416267943,
    distanceDriven: 259.1538462,
    hoursPlane: 4.966,
    portionsMeatCheese: 4.026472603,
    moneyElectricity: 80,
    moneyGas: 76.715,
    distanceDrivenCO2: 0.4005,
    moneyElectricityKwh: 0.15,
    kwhElectricityCO2: 0.292,
    moneyGasKwh: 14.82014388,
    currencyName: 'dollar',
    distanceUnit: 'miles',
  },
};

export const retrieveLocation = async () => {
  try {
    const { data: response } = await axios.get('https://ipapi.co/json/');
    return response.country_code;
  } catch (error) {
    return 'USA';
  }
};

export const dataKnownOf = (location) => {
  return data[location] !== undefined;
};

export const getHoursPublicTransport = (location) => {
  return data[location].hoursPublicTransport;
};

export const getDistanceDriven = (location) => {
  return data[location].distanceDriven;
};

export const getHoursPlane = (location) => {
  return data[location].hoursPlane;
};

export const getPortionsMeatCheese = (location) => {
  return data[location].portionsMeatCheese;
};

export const getMoneyElectricity = (location) => {
  return data[location].moneyElectricity;
};

export const getMoneyGas = (location) => {
  return data[location].moneyGas;
};

export const getHoursPublicTransportCO2 = (location) => {
  // values known: average of Netherlands and global average
  return location === 'NL' ? 0.788 : 1.528929284;
};

export const getDistanceDrivenCO2 = (location) => {
  return data[location].distanceDrivenCO2;
};

export const getHoursPlaneCO2 = () => {
  return 90;
};

export const getPortionsMeatCheeseCO2 = () => {
  return 1.35;
};

export const getMoneyElectricityCO2 = (location) => {
  // CO2 per kwh * kwh per currency
  return data[location].kwhElectricityCO2 * data[location].moneyElectricityKwh;
};

export const getMoneyGasCO2 = (location) => {
  // CO2 per kwh * kwh per currency
  return 0.206 * data[location].moneyGasKwh;
};

export const getCurrencyName = (location) => {
  return data[location].currencyName;
};

export const getCurrency = async () => {
  try {
    const { data: response } = await axios.get('https://ipapi.co/json/');
    return response.currency;
  } catch (error) {
    return 'USD';
  }
};

export const getDistanceUnit = (location) => {
  return data[location].distanceUnit;
};
