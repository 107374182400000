/* eslint-disable camelcase */
import axios from 'axios';

// This file contains all the api calls that are needed for the widgets

export const API_BASE_URL = 'https://treemendo.tech/api';
// when using the test backend server.js use the following url
// export const API_BASE_URL = 'http://localhost:5000/api';

export const jsonHeader = {
  'Content-Type': 'application/json',
};

// Registration
export const register = (email, firstname, lastname, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/v1/auth/register`, {
        email,
        firstname,
        lastname,
        password,
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

// Login
export const login = (email, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${API_BASE_URL}/v1/auth/login`,
        {
          email,
          password,
        },
        { headers: jsonHeader },
      )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// Account information
export const me = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/v1/auth/me`, {
        headers: { Authorization: 'Bearer '.concat(token) },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// Logout
export const logout = (token) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/v1/auth/logout`, null, {
        headers: { Authorization: 'Bearer '.concat(token) },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// forgot email
export const forgot = (email) =>
  new Promise((resolve, reject) => {
    axios
      .post(`${API_BASE_URL}/v1/auth/forgot`, {
        email,
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// reset password
export const reset = (token, email, password) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${API_BASE_URL}/v1/auth/reset`,
        {
          token,
          email,
          password,
        },
        { headers: jsonHeader },
      )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// plant a plant
export const plantAPlant = (
  token,
  plant_type_id,
  forest_id,
  planting_date,
  x,
  y,
  z,
) =>
  new Promise((resolve, reject) => {
    axios
      .put(
        `${API_BASE_URL}/v1/plant-type/plant`,
        {
          plant_type_id,
          forest_id,
          planting_date,
          x,
          y,
          z,
        },
        {
          headers: {
            Authorization: 'Bearer '.concat(token),
            'Content-Type': 'application/json',
          },
        },
      )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// show forest
export const showAForest = (forestID) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/v1/forests/${forestID}/public`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// list forests
export const listAllForests = (token) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/v1/forests`, {
        headers: { Authorization: 'Bearer '.concat(token) },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// show a gift
export const showAGift = (giftCode) =>
  new Promise((resolve, reject) => {
    axios
      .get(`${API_BASE_URL}/v1/gifts/${giftCode}`)
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });

// redeem a gift
export const redeemAGift = (token, gift_code) =>
  new Promise((resolve, reject) => {
    axios
      .post(
        `${API_BASE_URL}/v1/gifts/redeem`,
        {
          gift_code,
        },
        {
          headers: {
            Authorization: 'Bearer '.concat(token),
            'Content-Type': 'application/json',
          },
        },
      )
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((resp) => {
        reject(resp);
      });
  });
