/* eslint-disable react/no-unused-state */
/* eslint-disable no-alert */
/* eslint-disable react/static-property-placement */
import React from 'react';
import userImg from '../pictures/user.png';
import lockImg from '../pictures/lock.png';
import mailImg from '../pictures/mail.png';
import locationImg from '../pictures/location.png';
import cross from '../pictures/cross.png';
import qmark from '../pictures/questionMark.png';

import { MyContext } from '../Context';
import history from '../history';
import {
  redeemAGift,
  plantAPlant,
  register,
  login,
} from '../connectors/treemendo';
import './registerForm.css';

// The following regular expressions are used to check if the named inputfield matches the requirements
const nameRegExp = /^[a-zA-Z\u00C0-\u00FF\s-.]*$/;
const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z0-9!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{8,25}$/;

class RegisterForm extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      password: '',
      checkPassword: '',
      mail: '',
      token: '',
      popupMessage: '',
      popupBool: false,
      automaticDeleteBool: false,
      timeout: 0,
    };
    if (props.widget === 'giftCode') {
      this.state = {
        forestId:
          typeof context !== 'undefined' &&
          typeof context.state !== 'undefined' &&
          typeof context.state.forestId !== 'undefined'
            ? context.state.forestId
            : '',
        locationNS:
          typeof context !== 'undefined' &&
          typeof context.state !== 'undefined' &&
          typeof context.state.location[1] !== 'undefined'
            ? context.state.location[1].toFixed(6)
            : '--',
        locationWE:
          typeof context !== 'undefined' &&
          typeof context.state !== 'undefined' &&
          typeof context.state.location[0] !== 'undefined'
            ? context.state.location[0].toFixed(6)
            : '--',
      };
    }
  }

  componentDidMount() {
    if (document !== null && document.getElementById('firstNameField') !== null)
      document.getElementById('firstNameField').focus();
  }

  getFormId = () => {
    const { widget } = this.props;
    if (widget !== undefined) return `${widget}RegisterForm`;
    return '';
  };

  goToErrorPage = () => {
    const { widget } = this.props;
    if (widget === 'giftCode') {
      history.push('/errorgc');
    } else {
      history.push('/errorcarbon');
    }
  };

  dataComplete = () => {
    const { firstName, lastName, password, checkPassword, mail } = this.state;
    const nameCorrect = nameRegExp.test(firstName) && nameRegExp.test(lastName);
    const emailCorrect = emailRegExp.test(mail);
    const passwordCorrect = passwordRegExp.test(password);
    const passwordsMatch = password === checkPassword;
    if (
      !firstName ||
      !lastName ||
      !password ||
      !checkPassword ||
      !mail ||
      !nameCorrect ||
      !emailCorrect ||
      !passwordCorrect ||
      !passwordsMatch
    ) {
      return true;
    }
    return false;
  };

  sendRegisterPost = () => {
    const { mail, firstName, lastName, password } = this.state;
    const { setError } = this.context;
    register(mail, firstName, lastName, password)
      .then(() => {
        this.sendLoginPost();
      })
      .catch((error) => {
        if (
          error !== undefined &&
          error.response !== undefined &&
          error.response.data !== undefined &&
          error.response.data.errors !== undefined &&
          error.response.data.errors.email !== undefined &&
          error.response.data.errors.email[0] ===
            'The email has already been taken.'
        ) {
          this.emailTakenError();
        } else {
          setError('Something went wrong while registering.');
          this.goToErrorPage();
        }
      });
  };

  sendLoginPost = () => {
    const { mail, password } = this.state;
    const { setError } = this.context;
    login(mail, password)
      .then((resp) => {
        this.setState({ token: resp.meta.token });
        const { widget, nextItem } = this.props;
        if (widget === 'giftCode') this.sendRedeemGiftRequest();
        else {
          nextItem();
        }
      })
      .catch(() => {
        setError('Something went wrong while logging in.');
        this.goToErrorPage();
      });
  };

  // function used in gift code widget only
  sendRedeemGiftRequest = () => {
    const { token } = this.state;
    const { state, setError } = this.context;
    redeemAGift(token, state.giftcode)
      .then(() => {
        this.sendPlantRequest();
      })
      .catch(() => {
        setError('Something went wrong while redeeming your gift.');
        history.push('/errorgc');
      });
  };

  // function used in gift code widget only
  sendPlantRequest = () => {
    const { token, forestId, locationNS, locationWE } = this.state;
    const { state, setError, setPath } = this.context;
    const today = new Date().toISOString().split('T');
    const time = today[1].split('.')[0];
    const date = `${today[0]} ${time}`;
    plantAPlant(
      token,
      state.plantType.plant_type_id,
      forestId,
      date,
      locationNS,
      locationWE,
      0,
    )
      .then(() => {
        setPath('/register');
        history.push('/final');
      })
      .catch(() => {
        setError('Something went wrong while planting your tree.');
        history.push('/errorgc');
      });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    const { password, firstName, checkPassword } = this.state;
    const { setUserData } = this.context;
    if (password !== checkPassword) alert('Passwords do not match!');
    else {
      setUserData(firstName);
      this.sendRegisterPost();
    }
    event.preventDefault();
  };

  giveNameFeedback = (name) => {
    this.closePopup();
    if (!nameRegExp.test(name)) {
      this.setState({
        popupMessage:
          'Please make sure your name does not contain a number or special characters',
        popupBool: true,
        automaticDeleteBool: true,
      });
    }
  };

  givePasswordFeedback = () => {
    this.closePopup();
    const { password } = this.state;
    if (!passwordRegExp.test(password)) {
      this.setState({
        popupMessage:
          'Please make sure your password matches the requirements:\n- 8-25 characters\n- at least 1 capital letter\n- at least 1 normal letter\n- at least 1 number or special character',
        popupBool: true,
        automaticDeleteBool: true,
      });
    }
  };

  checkPasswordsMatch = () => {
    this.closePopup();
    const { password, checkPassword } = this.state;
    if (password !== checkPassword) {
      this.setState({
        popupMessage: 'Passwords do not match!',
        popupBool: true,
        automaticDeleteBool: true,
      });
    }
  };

  giveEmailFeedback = () => {
    this.closePopup();
    const { mail } = this.state;
    if (!emailRegExp.test(mail)) {
      this.setState({
        popupMessage: 'Please make sure your emailaddres is of a valid type!',
        popupBool: true,
        automaticDeleteBool: true,
      });
    }
  };

  helpPopup = () => {
    this.closePopup();
    this.setState({
      popupMessage:
        'Password should contain:\n- 8-25 characters\n- at least 1 capital letter\n- at least 1 normal letter\n- at least 1 number or special character',
      popupBool: true,
      automaticDeleteBool: true,
    });
  };

  emailTakenError = () => {
    this.closePopup();
    this.setState({
      popupMessage: 'Email already in use!',
      popupBool: true,
      automaticDeleteBool: true,
    });
    if (document !== null && document.getElementById('popupBlock') !== null) {
      const emailTakenPopup = document.getElementById('popupBlock');
      emailTakenPopup.style.color = 'red';
      emailTakenPopup.style.fontSize = 'medium';
    }
  };

  closePopup = () => {
    const { timeout } = this.state;
    clearTimeout(timeout);
    this.setState({
      popupBool: false,
      automaticDeleteBool: false,
    });
  };

  popup = () => {
    const { popupMessage, popupBool } = this.state;
    if (popupBool) {
      return (
        <div id="popupBlock">
          <input
            type="image"
            className="transparent_button"
            id="closeButton"
            alt="close button"
            src={cross}
            onClick={this.closePopup}
          />
          <p>{popupMessage}</p>
        </div>
      );
    }
    return null;
  };

  deletePopupAuto = () => {
    const { popupBool, automaticDeleteBool } = this.state;
    if (popupBool && automaticDeleteBool) {
      this.setState({
        automaticDeleteBool: false,
        timeout: setTimeout(this.closePopup, 10000),
      });
    }
  };

  mainCaption = () => {
    const { widget } = this.props;
    if (widget === 'giftCode')
      return (
        <tr>
          <td />
          <td>
            <input disabled value="Tree owner:" />
          </td>
        </tr>
      );
    return null;
  };

  locationForm = () => {
    const { widget } = this.props;
    if (widget === 'giftCode') {
      const { locationNS, locationWE } = this.state;
      return (
        <tr>
          <td className="common_padding_bottom">
            <img
              src={locationImg}
              alt="Location:"
              height="28.6px"
              width="28px"
            />
          </td>
          <td>
            <input
              disabled
              value={`${locationNS} ${
                locationNS < 0 ? 'S' : 'N'
              } ${locationWE} ${locationWE < 0 ? 'W' : 'E'}`}
            />
          </td>
        </tr>
      );
    }
    return null;
  };

  plantingButtonText = () => {
    const { widget } = this.props;
    if (widget === 'giftCode') return 'CONFIRM PLANTING';
    if (widget === 'carbon') return 'GO TO PAY';
    return '';
  };

  render() {
    if (this.context !== undefined) {
      const { firstName, lastName, mail, password, checkPassword } = this.state;
      return (
        <div id={this.getFormId()}>
          {this.popup()}
          {this.deletePopupAuto()}
          <form id="registerForm" onSubmit={this.handleSubmit}>
            <table>
              <tbody>
                {this.mainCaption()}

                <tr>
                  <td rowSpan="2" className="common_padding_bottom">
                    <img
                      src={userImg}
                      alt="Name:"
                      height="34.76px"
                      width="24.42px"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="firstName"
                      id="firstNameField"
                      required
                      placeholder="first name..."
                      value={firstName}
                      onChange={this.handleChange}
                      onBlur={() => this.giveNameFeedback(firstName)}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <input
                      type="text"
                      name="lastName"
                      required
                      placeholder="last name..."
                      value={lastName}
                      onChange={this.handleChange}
                      onBlur={() => this.giveNameFeedback(lastName)}
                    />
                  </td>
                </tr>

                <tr>
                  <td rowSpan="2" className="common_padding_bottom">
                    <img
                      src={lockImg}
                      alt="Password:"
                      height="28.28px"
                      width="23.8px"
                    />
                  </td>
                  <td>
                    <input
                      type="password"
                      name="password"
                      required
                      placeholder="password..."
                      value={password}
                      onChange={this.handleChange}
                      pattern={
                        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])[A-Za-z0-9!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~]{8,25}$'
                      }
                      onBlur={this.givePasswordFeedback}
                    />
                  </td>
                  <td>
                    <input
                      form="fakeForm"
                      type="image"
                      className="transparent_button"
                      id="helpButton"
                      alt="help button"
                      src={qmark}
                      onClick={this.helpPopup}
                    />
                  </td>
                </tr>

                <tr>
                  <td>
                    <input
                      type="password"
                      name="checkPassword"
                      required
                      placeholder="same password..."
                      value={checkPassword}
                      onChange={this.handleChange}
                      onBlur={this.checkPasswordsMatch}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="common_padding_bottom">
                    <img
                      src={mailImg}
                      alt="Mail:"
                      height="17.6px"
                      width="24.8px"
                    />
                  </td>
                  <td>
                    <input
                      type="email"
                      name="mail"
                      required
                      placeholder="email address..."
                      value={mail}
                      onChange={this.handleChange}
                      onBlur={this.giveEmailFeedback}
                    />
                  </td>
                </tr>

                {this.locationForm()}
              </tbody>
            </table>

            <br />
            <button
              type="submit"
              className="button middle_block_button"
              disabled={this.dataComplete()}
            >
              {this.plantingButtonText()}
            </button>
          </form>
        </div>
      );
    }
    return null;
  }
}

export default RegisterForm;
