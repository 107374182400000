import React, { Component } from 'react';
import './carbonTemplate.css';

const mobileFormat = 500;

class CarbonTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children, imageMobile, image } = this.props;
    return (
      <div
        className="main_container"
        id="carbonContainer"
        style={{
          backgroundImage: `url(${
            window.innerWidth <= mobileFormat ? imageMobile : image
          })`,
        }}
      >
        {children}
      </div>
    );
  }
}

export default CarbonTemplate;
