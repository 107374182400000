import React, { Component } from 'react';
import './emissionResult.css';

class EmissionResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      percentage,
      amountTrees,
      formatter,
      basePrice,
      exchangeRate,
    } = this.props;
    return (
      <div id="emissionResultGrid">
        <div id="yrEmissionsText">
          <i>capture {percentage}% of your yearly emissions</i>
        </div>
        <div id="nrTreesText">{amountTrees} trees</div>
        <div id="moneyTreesText">
          {formatter.format(basePrice * amountTrees * exchangeRate)}
        </div>
      </div>
    );
  }
}

export default EmissionResult;
