/* eslint-disable react/static-property-placement */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import * as progBar from 'progressbar.js';
import * as devDetect from 'react-device-detect';
import { Textfit } from 'react-textfit';
import { MyContext } from '../Context';
import history from '../history';
import MiddleBlock from './components/MiddleBlock';
import ShareMenu from '../components/ShareMenu';
import './final.css';

import statsImg from '../pictures/stats.png';
import triangleImg from '../pictures/triangle.png';
import locationImg from '../pictures/location.png';
import shareImg from '../pictures/share.png';
import appStoreBtn from '../pictures/apple.png';
import playStoreBtn from '../pictures/android.png';

class Final extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    this.state = {
      name:
        context !== undefined &&
        context.state !== undefined &&
        context.state.firstName !== undefined
          ? context.state.firstName
          : '',
      tree:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType.name !== undefined
          ? context.state.plantType.name
          : 'tree',
      treeImage:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined &&
        context.state.plantType.photo !== undefined
          ? context.state.plantType.photo
          : '',
      health: (Math.random() * (100 - 85) + 85).toFixed(2), // set to a random value as asked, could also be taken from plantType
      locationNS:
        context !== undefined &&
        context.state !== undefined &&
        context.state.location[1] !== undefined
          ? context.state.location[1].toFixed(6)
          : '--',
      locationWE:
        context !== undefined &&
        context.state !== undefined &&
        context.state.location[0] !== undefined
          ? context.state.location[0].toFixed(6)
          : '--',
      shareData: {
        title: 'Treemendo',
        text:
          context !== undefined &&
          context.state !== undefined &&
          context.state.plantType !== undefined &&
          context.state.plantType.name !== undefined
            ? `I have just planted ${
                /[aeiouAEIOU]/.test(context.state.plantType.name.charAt(0))
                  ? 'an'
                  : 'a'
              } ${
                context.state.plantType.name
              } via Treemendo! Plant a tree too!\n`
            : '',
        url: 'https://treemendo.com',
      },
      isMobileDevice:
        devDetect.isAndroid || devDetect.isIOS || devDetect.isIOS13,
      osName: '',
      supportsNativeShare: navigator.share !== undefined,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0, 0); // scroll to the top of the page
    if (devDetect.isAndroid) {
      this.setState({
        osName: 'android',
      });
    } else if (devDetect.isIOS || devDetect.isIOS13) {
      this.setState({
        osName: 'ios',
      });
    } else {
      this.setState({
        osName: 'other',
      });
    }
    if (this.context !== undefined) {
      const { state, setPath } = this.context;
      if (
        state !== undefined &&
        state.path !== undefined &&
        state.path !== '/register' &&
        state.path !== '/final_on' &&
        !state.test
      ) {
        history.push('/code');
      } else if (
        state === undefined ||
        state.firstName === undefined ||
        state.location[0] === undefined ||
        state.location[1] === undefined ||
        state.path === undefined ||
        state.plantType === undefined ||
        state.plantType.photo === undefined ||
        state.plantType.name === undefined
      ) {
        history.push('/errorgc');
      } else {
        setPath('/final_on');
        // The circular progressbar around the tree image, depicting the health
        setTimeout(() => {
          const { health } = this.state;
          this.circProgressBar().animate(health / 100);
        });
      }
    } else {
      // go to errorPage if the context is undefined
      history.push('/errorgc');
    }
  };

  // Settings for the circular progressbar
  circProgressBar = () => {
    return new progBar.Circle('#containerProgBar', {
      strokeWidth: 3,
      easing: 'easeInOut',
      duration: 4000,
      color: 'rgb(255,255,255,1)',
      trailColor: 'rgb(255,255,255,0.5)',
      trailWidth: 1,
    });
  };

  // Handling the click on the button in the mobile versions
  // referencing will be based on the operating system, android or ios
  clickHandler = (os) => {
    switch (os) {
      case 'android': // this link needs to be changed to the playstore link of the app
        window.open('https://google.com');
        break;
      case 'ios': // this link needs to be changed to the app store link of the app
        window.open('https://treemendo.com');
        break;
      default:
        // users actually should not be able to get to this link but just as a default case let this link to the home page
        window.open('https://treemendo.com');
        break;
    }
  };

  // Code to use the native sharing functionality of mobile devices
  // the sharebutton is only rendered if the browser supports the functionality
  // so it is only able to enter this function when it is supported, otherwise it will just do nothing.
  // navigator.share only works if the page runs over https, or for some browsers in test modus (localhost)
  shareButtonClickHandler = () => {
    const { supportsNativeShare } = this.state;
    if (supportsNativeShare) {
      const { shareData } = this.state;
      navigator.share(shareData);
    }
  };

  // If native sharing is supported by the browser a share symbol will be shown
  nativeShareButton = () => {
    const { supportsNativeShare, isMobileDevice } = this.state;
    if (supportsNativeShare && isMobileDevice) {
      return (
        <button
          type="button"
          id="shareButton"
          onClick={this.shareButtonClickHandler}
        >
          <img id="shareImg" alt="Share on Social Media!" src={shareImg} />
        </button>
      );
    }
    return null;
  };

  // Creating different designs based on the operating system (mobile or desktop)
  osSpecificButton = () => {
    const { isMobileDevice, osName } = this.state;
    if (isMobileDevice) {
      return (
        <button
          type="button"
          id="downloadBtn"
          className="button"
          onClick={() => this.clickHandler(osName)}
        >
          Complete planting &amp;
          <br />
          Download the app!
        </button>
      );
    }
    return (
      <div id="finalInnerBlock">
        {/* This text has been put in a textfit component such that the text always is just as wide as the download buttons */}
        <Textfit mode="single">
          <p className="center_text" id="finalTextDownloadBlock">
            Complete planting &amp; Download the app!
          </p>
        </Textfit>
        <button
          type="button"
          id="downloadAppstoreButton"
          onClick={() => this.clickHandler('ios')}
        >
          <img
            id="downloadAppstoreBadge"
            alt="Get it in the App Store"
            src={appStoreBtn}
          />
        </button>
        <button
          type="button"
          id="downloadPlaystoreButton"
          onClick={() => this.clickHandler('android')}
        >
          <img
            id="downloadPlaystoreBadge"
            alt="Get it on Google Play"
            src={playStoreBtn}
          />
        </button>
      </div>
    );
  };

  render() {
    const {
      name,
      tree,
      locationNS,
      locationWE,
      health,
      treeImage,
    } = this.state;
    return (
      <MiddleBlock page="final" title={`Congratulations ${name}!`}>
        <p className="subtitle center_text">
          You just planted
          {/[aeiouAEIOU]/.test(tree.charAt(0)) ? ' an ' : ' a '}
          {tree}!
        </p>
        <div id="middlePartFinal">
          <div id="containerProgBar" />
          <img
            className="middle_block_img"
            id="finalTreeImage"
            src={treeImage}
            alt="treeImage"
          />
          <table id="infoInCircle">
            <tbody>
              <tr>
                <td>
                  <img src={locationImg} alt="Location:" width="10px" />
                </td>
                <td>
                  {locationNS}
                  <b>{locationNS < 0 ? 'S ' : 'N '}</b>
                  {locationWE}
                  <b>{locationWE < 0 ? 'W' : 'E'}</b>
                </td>
              </tr>
              <tr>
                <td>
                  <img src={statsImg} alt="Health:" width="10px" />
                </td>
                <td>
                  HEALTH:
                  {'   '}
                  <img src={triangleImg} alt="" height="7px" width="7px" />
                  {health}%
                </td>
              </tr>
            </tbody>
          </table>
          {this.nativeShareButton()}
        </div>

        <ShareMenu widget="giftCode" />
        {this.osSpecificButton()}
      </MiddleBlock>
    );
  }
}

export default Final;

// to locally test over https see:
// https://create-react-app.dev/docs/using-https-in-development/

// installed:  npm i react-device-detect
// https://www.npmjs.com/package/react-device-detect
// this might have to be updated later if os versions change
// (as you can see for some reason ios13 already has its own check)

// installed: npm i progressbar.js
// documentation: https://kimmobrunfeldt.github.io/progressbar.js/

// installed: npm i react-textfit --save
// documentation: https://www.npmjs.com/package/react-textfit#api

// installed: npm i react-share --save
// documentation: https://www.npmjs.com/package/react-share

// link for apple guidelines of using badges + badgemaker
// https://developer.apple.com/app-store/marketing/guidelines/#section-badges

// link for play store guidelines badges + badgemaker
// https://play.google.com/intl/en_us/badges/
