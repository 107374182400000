// This map contains the coordinates of the polygons depicting Treemendo's current forests
// When the backend gets updated such that it also returns the polygon coordinates this file is not needed anymore

export default {
  data: [
    {
      forest_id: 1,
      lat: -2.358538111744565,
      lon: 51.366208864526726,
      coordinates: [
        [
          [-2.358708, 51.366425],
          [-2.358808, 51.367082],
          [-2.359197, 51.367033],
          [-2.359309, 51.367075],
          [-2.36043, 51.366879],
          [-2.360436, 51.366808],
          [-2.360908, 51.366656],
          [-2.360994, 51.366457],
          [-2.360897, 51.366263],
          [-2.360159, 51.366491],
        ],
        [
          [-2.35832, 51.366084],
          [-2.357621, 51.365945],
          [-2.357346, 51.36586],
          [-2.357098, 51.365779],
          [-2.356494, 51.365554],
          [-2.35694, 51.365231],
          [-2.357481, 51.36554],
          [-2.358198, 51.365846],
          [-2.358473, 51.365873],
        ],
      ],
    },
    {
      forest_id: 2,
      lat: -3.375165,
      lon: 55.454154,
      coordinates: [
        [
          [-3.374892, 55.4571535],
          [-3.375708, 55.4570684],
          [-3.3781761, 55.4563201],
          [-3.38101, 55.4561741],
          [-3.379872, 55.4555049],
          [-3.3785943, 55.4542942],
          [-3.3781229, 55.4541482],
          [-3.3758247, 55.4517024],
          [-3.3756959, 55.451459],
          [-3.3751805, 55.4513251],
          [-3.3745578, 55.4512156],
          [-3.3731835, 55.4520066],
          [-3.3730332, 55.4521769],
          [-3.3720669, 55.4525907],
          [-3.3709718, 55.4528949],
          [-3.3705853, 55.4532356],
          [-3.3711006, 55.4534181],
          [-3.3722602, 55.4544159],
          [-3.3722387, 55.4547322],
          [-3.3730117, 55.4555961],
          [-3.3738277, 55.4558638],
          [-3.374892, 55.4571535],
        ],
      ],
    },
    {
      forest_id: 3,
      lat: 37.85765928354607,
      lon: -1.5913064355580389,
      coordinates: [
        [
          [37.857317, -1.58936],
          [37.856228, -1.591966],
          [37.856673, -1.59276],
          [37.856909, -1.593018],
          [37.857317, -1.593082],
          [37.857649, -1.593135],
          [37.858476, -1.591966],
          [37.859688, -1.590508],
          [37.858272, -1.589961],
        ],
      ],
    },
  ],
};
