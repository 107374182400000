/* eslint-disable react/no-unused-state */
import React from 'react';

// Defining the context we are using for passing data through the pages
const MyContext = React.createContext();

class MyProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      test: false,
      path: JSON.parse(sessionStorage.getItem('path')) || '',
      giftCode: JSON.parse(sessionStorage.getItem('giftcode')) || '',
      plantType: JSON.parse(sessionStorage.getItem('plantType')) || '',
      location: JSON.parse(sessionStorage.getItem('location')) || '',
      forestId: JSON.parse(sessionStorage.getItem('forestId')) || '',
      firstName: JSON.parse(sessionStorage.getItem('firstName')) || '',
      error:
        JSON.parse(sessionStorage.getItem('error')) ||
        'Something seems broken in our context',
    };
  }

  render() {
    const { children } = this.props;
    return (
      <MyContext.Provider
        value={{
          state: this.state,
          setGiftCode: (gc) => {
            this.setState({
              giftcode: gc,
            });
            sessionStorage.setItem('giftCode', JSON.stringify(gc));
          },
          setPath: (newPath) => {
            this.setState({
              path: newPath,
            });
            sessionStorage.setItem('path', JSON.stringify(newPath));
          },
          setTree: (newTree) => {
            this.setState({
              plantType: newTree,
            });
            sessionStorage.setItem('plantType', JSON.stringify(newTree));
          },
          setLocation: (newLocation) => {
            this.setState({
              location: newLocation,
            });
            sessionStorage.setItem('location', JSON.stringify(newLocation));
          },
          setUserData: (newFirstName) => {
            this.setState({
              firstName: newFirstName,
            });
            sessionStorage.setItem('firstName', JSON.stringify(newFirstName));
          },
          setForestId: (id) => {
            this.setState({
              forestId: id,
            });
            sessionStorage.setItem('forestId', JSON.stringify(id));
          },
          setError: (errormessage) => {
            this.setState({
              error: errormessage,
            });
            sessionStorage.setItem('error', JSON.stringify(errormessage));
          },
        }}
      >
        {children}
      </MyContext.Provider>
    );
  }
}

export { MyContext, MyProvider };
