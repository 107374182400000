import React from 'react';
import * as devDetect from 'react-device-detect';
import ProgressBar from '../components/ProgressBar';
import CarbonTemplate from './components/CarbonTemplate';
import CarbonBlockGrid from './components/CarbonBlockGrid';
import ShareMenu from '../components/ShareMenu';
import confirm from './Backgrounds/Desktop/confirm_compensate_back.png';
import confirmMobile from './Backgrounds/Mobile/confirm_compensate_back_mobile.png';
import forestImage from './Assets/forest_image.png';

import './confirmationPage.css';

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = 0;
  }

  componentDidMount() {
    if (
      document !== null &&
      document.getElementById('plantLaterText') !== null
    ) {
      // Handling the click on the button in the mobile versions
      // referencing will be based on the operating system, android or ios
      const link = document.getElementById('plantLaterText');
      if (devDetect.isAndroid) {
        // this link needs to be changed to the playstore link of the app
        link.setAttribute('href', 'https://google.com');
      } else if (devDetect.isIOS || devDetect.isIOS13) {
        // this link needs to be changed to the app store link of the app
        link.setAttribute('href', 'https://apple.com');
      } else {
        // for desktop users, this link could be changed to a page on treemendo.com with information about the app
        link.setAttribute('href', 'https://treemendo.com');
      }
    }
  }

  renderConfirmation = () => {
    const { currentId, previousItem, amountTrees, percentage } = this.props;
    return (
      <CarbonBlockGrid
        currentId={currentId}
        previousItem={previousItem}
        carbonItemsLength={currentId - 3}
        page="confirmation"
        label="Finish &#38; Pay"
      >
        <div id="confirmationDescription">
          <p className="description">
            Plant <span className="nr_trees">{amountTrees}</span> trees <br />
            to compensate {percentage}% of your emissions
          </p>
        </div>

        <div id="confirmationGrid">
          <div>
            <img src={forestImage} alt="logo" id="forestImg" />
            <p className="nr_trees" id="confirmNrTrees">
              {amountTrees}
            </p>
            <p id="confirmTreesText">trees</p>
          </div>
          <ShareMenu
            widget="carbon"
            amountTrees={amountTrees}
            percentage={percentage}
          />
          <div id="plantAndPay">
            <button
              className="button"
              type="button"
              id="plantPayButton"
              onClick={() =>
                window.open('https://treemendo.com/product/your-first-tree/')
              }
            >
              PLANT & PAY
            </button>
            <a
              href="https://treemendo.com"
              target="_blank"
              rel="noopener noreferrer"
              id="plantLaterText"
            >
              or plant later with the app
            </a>
          </div>
        </div>
      </CarbonBlockGrid>
    );
  };

  render() {
    const { currentId, amountPages } = this.props;
    return (
      <CarbonTemplate imageMobile={confirmMobile} image={confirm}>
        <div className="carbon_item_container hor_center">
          <ProgressBar curId={currentId} nrItems={amountPages} />
          {this.renderConfirmation()}
        </div>
      </CarbonTemplate>
    );
  }
}

export default ConfirmationPage;
