/* eslint-disable react/static-property-placement */
/* eslint-disable no-alert */
/* eslint-disable no-return-assign */
/* eslint-disable react/button-has-type */
import 'font-awesome/css/font-awesome.css'; // required for icons
import React from 'react';
import FontAwesome from 'react-fontawesome';
import mapboxgl from 'mapbox-gl';
import { MyContext } from '../Context';
import 'mapbox-gl/dist/mapbox-gl.css';
import forestsCoordinates from './forestsCoordinates';
import { showAForest } from '../connectors/treemendo';
import history from '../history';
import ProgressBar from '../components/ProgressBar';
import available from '../pictures/available_cursor.png';
import unavailable from '../pictures/unavailable_cursor.png';
import './planting.css';

class Planting extends React.Component {
  static contextType = MyContext;

  mapBoxAccessToken =
    'pk.eyJ1IjoidHJlZW1lbmRvIiwiYSI6ImNrYTZ5OGhxNDBhOHgyem54NWlkdHRxdjIifQ.4k5jLe4H21gjBqSu6ryWGw';

  constructor(props, context) {
    super(props);
    this.forestId =
      context !== undefined &&
      context.state !== undefined &&
      context.state.forestId !== undefined
        ? context.state.forestId
        : 0;
    this.mapContainer = null;
    this.map = null;
    this.markerRef = null;
    this.state = {
      lng: null,
      lat: null,
      zoom: 1.0,
      maxZoom: 16.5,
      treeLabel:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined &&
        context.state.plantType.name !== undefined
          ? context.state.plantType.name
          : 'tree',
      forestLabel: null,
      currentLocation: [], // current selected location for tree
      showReturnButton: false,
    };

    this.forestCoordinates = []; // coordinates of outlines of forest
    this.plantsCoordinates = []; // coordinates of existing trees in forest
    this.minDistanceTrees = 2; // minimum distance between trees in meters

    this.flyHandleClick = this.flyHandleClick.bind(this);
    this.confirmHandleClick = this.confirmHandleClick.bind(this);

    mapboxgl.accessToken = this.mapBoxAccessToken;
  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to the top of the page
    if (this.context !== undefined) {
      const { state, setPath } = this.context;
      if (
        state !== undefined &&
        state.path !== undefined &&
        state.path !== '/validated' &&
        state.path !== '/plant_on' &&
        state.path !== '/register_on' &&
        !state.test
      ) {
        history.push('/code');
      } else if (
        // go to errorPage if the context.state is undefined
        state === undefined ||
        state.path === undefined ||
        state.plantType === undefined ||
        state.plantType.name === undefined
      ) {
        history.push('/errorgc');
      } else {
        setPath('/plant_on');
        setTimeout(() => {
          // Create a new map
          this.createMap();
          // When map is loaded
          this.map.on('load', async () => {
            await this.storeCoordinates(); // Store coordinates from files in global variables
            this.addForestToMap(this.map); // Add forest to the map
            this.flyHandleClick(); // Fly to forest position
          });
          // Click function
          this.map.on('click', this.selectLocation);
          // Hover function
          this.map.on('mousemove', (e) => {
            const canvas = this.map.getCanvas();
            const cursorCoordinates = e.lngLat.toArray();
            if (
              this.locationInForest(cursorCoordinates) &&
              !this.tooCloseToOtherTree(cursorCoordinates)
            ) {
              canvas.style.cursor = `url(${available}) 20 20, auto`;
            } else {
              canvas.style.cursor = `url(${unavailable}) 20 20, auto`;
            }
          });
          // Show return button listeners
          this.map.on('mouseup', this.setReturnButtonListerner);
          this.map.on('zoom', this.setReturnButtonListerner);
          // Navigation controls
          this.map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
        }, 500);
      }
    } else {
      // go to errorPage if the context is undefined
      history.push('/errorgc');
    }
  }

  /** **************************************************
                   Loading the map
  **************************************************** */
  // Create a new map
  createMap = () => {
    const { lat, lng, zoom, maxZoom } = this.state;
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/satellite-v9',
      center: [lat, lng],
      zoom,
      maxZoom,
    });
  };

  // Retrieve forest from api connector
  sendShowAForest = async () => {
    let resp = '';
    const { setError } = this.context;
    await showAForest(this.forestId)
      .then((response) => {
        resp = response;
      })
      .catch(() => {
        // if the call fails go to errorpage
        setError('Something went wrong while retrieving the forest.');
        history.push('/errorgc');
      });
    if (
      resp === undefined ||
      resp.data === undefined ||
      resp.data.name === undefined
    ) {
      setError('Something went wrong while retrieving the forest.');
      history.push('/errorgc');
    }
    return resp;
  };

  // Collect plant coordinates from forest
  getPlantCoordinates = (plants) => {
    const coordinates = [];
    for (let i = 0; i < plants.length; i += 1) {
      coordinates.push([plants[i].location.x, plants[i].location.y]);
    }
    return coordinates;
  };

  // Store all coordinates in global variables and fly to start position
  storeCoordinates = async () => {
    const { setForestId, setError } = this.context;
    setForestId(this.forestId);
    const response = await this.sendShowAForest();
    if (
      response === undefined ||
      response.data === undefined ||
      response.data.name === undefined
    ) {
      setError('Something went wrong while retrieving the forest.');
      history.push('/errorgc');
    } else {
      this.setState({ forestLabel: response.data.name });
      this.setState({ zoom: 16.5 });
      // hardcoded forestcoordinates, when the backend gets updated to also give polygon coordinates this needs to be changed
      const allForests = forestsCoordinates.data;
      for (let i = 0; i < allForests.length; i += 1) {
        if (allForests[i].forest_id === this.forestId) {
          this.forestCoordinates = allForests[i].coordinates;
          this.setState({ lat: allForests[i].lat });
          this.setState({ lng: allForests[i].lon });
        }
      }
      this.plantsCoordinates = this.getPlantCoordinates(
        response.data['planted-plants'],
      );
    }
  };

  // Craft the forest in the correct format
  loadForest = () => {
    const forest = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    };
    // Add the coordinates of forest polygon(s)
    for (let i = 0; i < this.forestCoordinates.length; i += 1) {
      const area = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [],
        },
      };
      area.geometry.coordinates.push(this.forestCoordinates[i]);
      forest.data.features.push(area);
    }
    // Add coordinates of the trees in the forest
    for (let i = 0; i < this.plantsCoordinates.length; i += 1) {
      const point = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      };
      point.geometry.coordinates = this.plantsCoordinates[i];
      forest.data.features.push(point);
    }
    return forest;
  };

  addForestToMap = (map) => {
    map.addSource('forest', this.loadForest());
    // Add layer for forest polygon
    map.addLayer({
      id: 'forest',
      type: 'fill',
      source: 'forest',
      layout: {},
      paint: {
        'fill-color': '#088',
        'fill-opacity': 0.2,
      },
    });
    // Add layer for symbols of planted plants
    this.map.addLayer({
      id: 'points',
      type: 'circle',
      source: 'forest',
      paint: {
        'circle-radius': 3,
        'circle-color': '#79b172',
      },
      filter: ['==', '$type', 'Point'],
    });
  };

  /** **************************************************
                   Planting a tree
  **************************************************** */

  // Check if point is inside of a polygon
  // from http://alienryderflex.com/polygon/
  pointInPolygon = (x, y, polygonCoors) => {
    const polyCorners = polygonCoors.length;
    let j = polyCorners - 1;
    let oddNodes = false;

    for (let i = 0; i < polyCorners; i += 1) {
      if (
        (polygonCoors[i][1] < y && polygonCoors[j][1] >= y) ||
        (polygonCoors[j][1] < y && polygonCoors[i][1] >= y)
      ) {
        if (
          polygonCoors[i][0] +
            ((y - polygonCoors[i][1]) /
              (polygonCoors[j][1] - polygonCoors[i][1])) *
              (polygonCoors[j][0] - polygonCoors[i][0]) <
          x
        ) {
          oddNodes = !oddNodes;
        }
      }
      j = i;
    }
    return oddNodes;
  };

  // Check if the selected coordinates are in the forest
  locationInForest = (selectedCoordinates) => {
    for (let i = 0; i < this.forestCoordinates.length; i += 1) {
      if (
        this.pointInPolygon(
          selectedCoordinates[0],
          selectedCoordinates[1],
          this.forestCoordinates[i],
        )
      ) {
        return true;
      }
    }
    return false;
  };

  // calculate distance between two long/lat coordinates
  // copied from https://stackoverflow.com/questions/14560999/using-the-haversine-formula-in-javascript
  haversineDistance = (coords1, coords2) => {
    function toRad(x) {
      return (x * Math.PI) / 180;
    }

    const lon1 = coords1[0];
    const lat1 = coords1[1];

    const lon2 = coords2[0];
    const lat2 = coords2[1];

    const R = 6371e3; // radius of the earth in meter

    const x1 = lat2 - lat1;
    const dLat = toRad(x1);
    const x2 = lon2 - lon1;
    const dLon = toRad(x2);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;

    return d;
  };

  tooCloseToOtherTree = (coordinates) => {
    for (let i = 0; i < this.plantsCoordinates.length; i += 1) {
      const dis = this.haversineDistance(
        coordinates,
        this.plantsCoordinates[i],
      );
      if (dis < this.minDistanceTrees) {
        return true;
      }
    }

    return false;
  };

  // Save location and place dot and popup marker at selected location
  validLocation = (location, clickedCoordinates) => {
    // If a location was already selected, remove the old point first
    if (this.map.getLayer('point')) {
      this.map.removeLayer('point');
      this.map.removeSource('point');
    }
    // Create the point that marks the selected location
    this.map.addLayer({
      id: 'point',
      type: 'circle',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: location.toArray(),
          },
        },
      },
      paint: {
        'circle-radius': 3,
        'circle-color': '#FFFFFF',
      },
    });
    // Create the pop-up
    this.map.addLayer({
      id: 'label',
      type: 'custom',
      source: {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: location.toArray(),
          },
        },
      },
    });
    const { treeLabel } = this.state;
    const treePopup = new mapboxgl.Popup();
    treePopup.setLngLat(location);
    treePopup.setHTML(`<p>Your ${treeLabel}</p>`);
    treePopup.addTo(this.map);
    // Save current location
    this.setState({ currentLocation: clickedCoordinates });
  };

  // Flash polygon two times and show popup at selected location
  invalidLocation = (location) => {
    // Polygon flashes 2 times
    clearTimeout();
    this.map.setPaintProperty('forest', 'fill-opacity', 0.95);
    setTimeout(() => {
      this.map.setPaintProperty('forest', 'fill-opacity', 0.2);
    }, 300);
    setTimeout(() => {
      this.map.setPaintProperty('forest', 'fill-opacity', 0.95);
    }, 600);
    setTimeout(() => {
      this.map.setPaintProperty('forest', 'fill-opacity', 0.2);
    }, 900);
    // Show message at location that was clicked
    const popup = new mapboxgl.Popup();
    popup.setLngLat(location);
    popup.setHTML(`<p> Invalid location:<br> plant in forest!</p>`);
    popup.addTo(this.map);
    setTimeout(() => {
      popup.remove();
    }, 2000);
  };

  // Check if location is valid or not and respond accordingly
  selectLocation = (e) => {
    // Check if selected location is inside forest
    const clickedCoordinates = e.lngLat.toArray();
    if (
      this.locationInForest(clickedCoordinates) &&
      !this.tooCloseToOtherTree(clickedCoordinates)
    ) {
      this.validLocation(e.lngLat, clickedCoordinates);
    } else if (!this.locationInForest(clickedCoordinates)) {
      this.invalidLocation(e.lngLat);
    }
  };

  /** **************************************************
                    Buttons
   **************************************************** */

  // Show return button if forest is out of sight
  setReturnButtonListerner = () => {
    const { lng, lat } = this.state;
    const forestCenter = [lng, lat];
    const currentCoordinates = this.map.getCenter();
    const currentCenter = [currentCoordinates.lat, currentCoordinates.lng];
    const dis = this.haversineDistance(forestCenter, currentCenter);
    const zoomlevel = this.map.getZoom();
    if (zoomlevel < 14 || dis > 250) {
      this.setState({ showReturnButton: true });
    } else if (zoomlevel > 14 && dis <= 250) {
      this.setState({ showReturnButton: false });
    }
  };

  // Fly to the start position
  flyHandleClick() {
    const { lat, lng, zoom } = this.state;
    this.map.flyTo({
      center: [lat, lng],
      essential: true,
      zoom,
      speed: 0.75,
    });
  }

  // Log the last selected coordinate
  confirmHandleClick() {
    const { currentLocation } = this.state;
    const { setLocation, setPath } = this.context;
    if (currentLocation.length > 1) {
      setLocation(currentLocation);
      setPath('/plant');
      history.push('/register');
    }
  }

  /** **************************************************
                    Render page
   **************************************************** */

  render() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const {
      treeLabel,
      forestLabel,
      currentLocation,
      showReturnButton,
    } = this.state;
    return (
      <div>
        <div className="spinner" />
        <div id="worldMap" ref={(el) => (this.mapContainer = el)}>
          {' '}
          <div className="marker" ref={(el) => (this.marker = el)} />
        </div>

        <div className="block header">
          <ProgressBar page="planting" nrItems={5} />
          <p className="title header_title" id="plantingTitle">
            Select a location for your tree
          </p>
          <div id="treeFlairContainer">
            <FontAwesome name="leaf" id="treeFlairIcon" />
            <p id="treeFlairText">{treeLabel}</p>
          </div>
          <div id="locationContainer">
            <div id="coordinatesContainer">
              <FontAwesome name="map-marker" id="coordinatesIcon" />
              {currentLocation.length > 1 && (
                <p id="coordinatesText">
                  {`${currentLocation[1].toFixed(
                    6,
                  )} , ${currentLocation[0].toFixed(6)}`}
                </p>
              )}
            </div>
            <p id="forestText">{forestLabel}</p>
          </div>
        </div>

        <button
          className="button confirm_button"
          id="locationButton"
          onClick={this.confirmHandleClick}
          disabled={!currentLocation[0]}
        >
          <span> Confirm Location </span>
          <FontAwesome name="angle-right" id="locationButtonIcon" />
        </button>
        {showReturnButton && (
          <button
            className="button"
            id="flyButton"
            onClick={this.flyHandleClick}
          >
            <FontAwesome name="angle-left" id="flyButtonIcon" />
            <span> go to forest </span>
          </button>
        )}
      </div>
    );
  }
}

export default Planting;
