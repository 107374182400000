/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import 'font-awesome/css/font-awesome.css';
import './carbonCalculatorIndex.css';
import ProgressBar from '../components/ProgressBar';
import CarbonTemplate from './components/CarbonTemplate';
import CarbonBlockGrid from './components/CarbonBlockGrid';
import Arrows from './components/Arrows';
// import PaymentPage from './PaymentPage';
import ConfirmationPage from './ConfirmationPage';
import RegisterPage from './RegisterPage';
import { retrieveLocation, getCurrency } from './countryData';
import getSliderData from './carbonData';
import treemendoLogo from './Assets/logo_treemendo_beta.png';
import history from '../history';
import confirm from './Backgrounds/Desktop/confirm_compensate_back.png';
import ready from './Backgrounds/Desktop/ready_back.png';
// mobile backgrounds
import confirmMobile from './Backgrounds/Mobile/confirm_compensate_back_mobile.png';
import readyMobile from './Backgrounds/Mobile/ready_back_mobile.png';

const mobileFormat = 500;
const delay = 1000;

const PrettoSlider = withStyles({
  root: {
    color: '#79b172',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    paddingTop: '8px',
    color: 'rgba(0,0,0,0)',
    whiteSpace: 'nowrap',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

class CarbonCalculatorIndex extends React.Component {
  constructor(props) {
    super(props);
    this.location = null;
    this.state = {
      currentId: 0,
      emissionValue: 0, // amount of CO2 emission (excluding compensated CO2)
      compensateValue: 10, // percentage of emission user wants to compensate
      numberOfTrees: 0, // number of trees user wants to buy to compensate
      carbonItems: null,
    };
    this.averageCO2Emission = 0; // average CO2 emmission per country in kg
    this.co2pertree = 174; // the amount of CO2 in kg compensated by 1 tree
    this.amountPages = 0;
    this.formatter = null;
    this.currency = null;
    this.exchangeRate = null;
    this.basePrice = 5; // base price in EUR
    this.navigateProps = null;
    this.loadAll();
  }

  componentDidMount() {
    this.loadAll();
  }

  loadLocation = async () => {
    await retrieveLocation()
      .then((response) => {
        this.location = response;
      })
      .catch(() => {
        this.location = 'USA';
      });
  };

  setPrice = async () => {
    await getCurrency()
      .then((response) => {
        this.currency = response;
      })
      .catch(() => {
        this.currency = 'USD';
      });
    await axios
      .get('https://api.exchangeratesapi.io/latest') // retrieve exchange rates
      .then((response) => {
        this.exchangeRate = response.rates[this.currency];
      })
      .catch(() => {
        this.exchangeRate = 1;
      })
      .finally(() => {
        this.formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: this.currency,
        });
      });
  };

  loadCarbonItems = async () => {
    await this.loadLocation();
    const items = getSliderData(this.location);
    this.setState({ carbonItems: items });
    const { carbonItems } = this.state;
    this.amountPages = carbonItems.length + 3;
  };

  loadAll = async () => {
    await this.loadCarbonItems();
    await this.setPrice();
  };

  computeYearlyEmissions = (ofWhat = 'value') => {
    const { carbonItems } = this.state;

    let sum = 0;
    for (let i = 0; i < carbonItems.length; i += 1) {
      let sliderValue;
      if (carbonItems[i].done) {
        switch (ofWhat) {
          case 'avg':
            sliderValue = carbonItems[i].average;
            break;
          case 'max':
            sliderValue = carbonItems[i].sliderMax;
            break;
          default:
            sliderValue = carbonItems[i].value;
            break;
        }
        switch (carbonItems[i].duration) {
          case 'year':
            sum += sliderValue * carbonItems[i].co2TransmissionPerUnit;
            break;
          case 'month':
            sum += 12 * sliderValue * carbonItems[i].co2TransmissionPerUnit;
            break;
          case 'week':
            sum += 52 * sliderValue * carbonItems[i].co2TransmissionPerUnit;
            break;
          case 'day':
            sum += 365 * sliderValue * carbonItems[i].co2TransmissionPerUnit;
            break;
          default:
            break;
        }
      }
    }
    return sum;
  };

  setItemValue = (_, val) => {
    const { currentId } = this.state;
    const { carbonItems } = this.state;
    const newItems = carbonItems;
    const item = newItems.find((it) => it.id === currentId);
    item.value = val;
    this.setState({
      carbonItems: newItems,
    });
  };

  nextItem = () => {
    const {
      currentId,
      carbonItems,
      emissionValue,
      compensateValue,
    } = this.state;
    this.setState({
      currentId: Math.min(currentId + 1, this.amountPages),
    });
    if (currentId < carbonItems.length) {
      const newItems = carbonItems;
      newItems.find((item) => item.id === currentId + 1).done = true;
      this.setState({
        carbonItems: newItems,
      });
    }
    this.setState({
      emissionValue: this.computeYearlyEmissions(),
      numberOfTrees: Math.round(
        (compensateValue / 100) * (emissionValue / this.co2pertree),
      ),
    });
  };

  previousItem = () => {
    const { currentId } = this.state;
    return this.setState({ currentId: Math.max(currentId - 1, 0) });
  };

  getCurrentItem = () => {
    const { carbonItems, currentId } = this.state;
    return carbonItems.find((item) => item.id === currentId);
  };

  renderValueLabel = () => {
    const item = this.getCurrentItem();
    if (item.value === 0) return item.valueLabel[0];
    const percentage = (item.value / item.average) * 100;

    if (['Public Transport', 'Flight'].indexOf(item.label) > -1) {
      const percentageMax = (item.value / item.sliderMax) * 100;
      if (percentage >= 110 && percentageMax <= 25) return item.valueLabel[5];
      if (percentageMax >= 25 && percentageMax <= 50) return item.valueLabel[6];
      if (percentageMax >= 50) return item.valueLabel[9];
    }

    if (percentage >= 0 && percentage <= 25) return item.valueLabel[1];
    if (percentage >= 25 && percentage <= 60) return item.valueLabel[2];
    if (percentage >= 60 && percentage <= 90) return item.valueLabel[3];
    if (percentage >= 90 && percentage <= 110) return item.valueLabel[4];
    if (percentage >= 110 && percentage <= 150) return item.valueLabel[5];
    if (percentage >= 150 && percentage <= 180) return item.valueLabel[6];
    if (percentage >= 180 && percentage <= 280) return item.valueLabel[7];
    if (percentage >= 280 && percentage <= 380) return item.valueLabel[8];
    if (percentage >= 380) return item.valueLabel[9];

    const { setError } = this.context;
    setError('Something went wrong while logging in.');
    history.push('/errorcarbon');
    return null;
  };

  touchscreenButtonOutsideBlockMobile = () => {
    if (window.innerWidth > mobileFormat) return <div />;
    return (
      <div className="touch_block_container" id="outerTouchBlockContainer">
        <div
          role="button"
          onClick={() => this.previousItem()}
          className="outer_touch_block"
        />
        <div
          role="button"
          onClick={() => this.nextItem()}
          className="outer_touch_block"
        />
      </div>
    );
  };

  renderProgress = () => {
    const { currentId } = this.state;
    return <ProgressBar curId={currentId} nrItems={this.amountPages} />;
  };

  renderItem = () => {
    const { currentId, carbonItems } = this.state;
    const item = this.getCurrentItem();
    let sliderValue = Math.round(item.value ** (1 / item.exponent));
    if (item.label === 'Public Transport') {
      sliderValue = Math.round(item.value ** (1 / item.exponent) * 10) / 10;
    }
    return (
      <CarbonBlockGrid
        amountPages={this.amountPages}
        previousItem={() => this.previousItem()}
        nextItem={() => this.nextItem()}
        carbonItemsLength={carbonItems.length}
        currentId={currentId}
        page="item"
        label={item.label}
        text={item.question}
        textSize={0.9}
      >
        <div id="itemPageSlider">
          <PrettoSlider
            className="Slider"
            valueLabelDisplay="on"
            aria-label="pretto slider"
            scale={(x) => x ** item.exponent}
            max={Math.round(item.sliderMax ** (1 / item.exponent))}
            value={sliderValue}
            valueLabelFormat={(value) =>
              `${Math.round(value * 100) / 100} ${item.sliderUnit}`
            }
            step={0.5}
            onChange={this.setItemValue}
            onChangeCommitted={(obj, val) => {
              // Continue automatically only on mobile
              if (window.innerWidth < 500) {
                setTimeout(() => {
                  this.nextItem(obj, val);
                }, delay);
              }
            }}
          />
          <p className="value_label">
            <i>&quot;{this.renderValueLabel()}&quot;</i>
          </p>
        </div>
      </CarbonBlockGrid>
    );
  };

  renderCircles = () => {
    const { emissionValue, compensateValue } = this.state;
    // average CO2 emission (for current country) is computed
    this.averageCO2Emission = this.computeYearlyEmissions('avg');
    const maxCircleSize = 100; // in pixels
    const maxEmissions = this.computeYearlyEmissions('max');
    let yourCircleColor = '#79b172'; // green
    let averageCircleColor = '#79b172'; // green
    const emission = Math.round(
      emissionValue * ((100 - compensateValue) / 100),
    );
    const circlesize = Math.min(
      maxCircleSize,
      3 * emission * (maxCircleSize / maxEmissions),
    );
    const averageCirclesize = Math.min(
      maxCircleSize,
      3 * this.averageCO2Emission * (maxCircleSize / maxEmissions),
    );
    if (emission > this.averageCO2Emission) {
      yourCircleColor = '#FF5C33'; // red/orange
    } else if (emission < this.averageCO2Emission) {
      averageCircleColor = '#FF5C33'; // red/orange
    }
    return (
      <div id="emissionCircles">
        <span
          className="emission_circle"
          style={{
            width: `${circlesize}px`,
            height: `${circlesize}px`,
            color: yourCircleColor,
          }}
        />
        <span
          className="emission_circle"
          style={{
            width: `${averageCirclesize}px`,
            height: `${averageCirclesize}px`,
            color: averageCircleColor,
          }}
        />
      </div>
    );
  };

  renderCompensate = () => {
    const {
      compensateValue,
      emissionValue,
      numberOfTrees,
      currentId,
      carbonItems,
    } = this.state;
    const emission = Math.round(
      emissionValue * ((100 - compensateValue) / 100),
    );
    return (
      <CarbonTemplate imageMobile={confirmMobile} image={confirm}>
        <Arrows
          currentId={currentId}
          carbonItemsLength={carbonItems.length}
          nextItem={this.nextItem}
          prevItem={this.previousItem}
        />

        <div className="carbon_item_container hor_center">
          {this.renderProgress()}
          <CarbonBlockGrid
            amountPages={this.amountPages}
            previousItem={() => this.previousItem()}
            nextItem={() => this.nextItem()}
            carbonItemsLength={carbonItems.length}
            currentId={currentId}
            page="compensation"
            label="Compensation"
            text="Compensate your CO2 emission by planting trees"
            textSize={0.9}
          >
            {this.renderCircles()}
            <div id="emissionLabels">
              <p>
                Your emissions <br /> {Math.round(emission)}kg CO<sub>2</sub>
              </p>
              <p>
                Average <br /> {Math.round(this.averageCO2Emission)}kg CO
                <sub>2</sub>
              </p>
            </div>

            <div id="compensationPageSlider">
              <PrettoSlider
                valueLabelDisplay="on"
                aria-label="pretto slider"
                min={0}
                max={100}
                value={compensateValue}
                valueLabelFormat={(value) => `${value} ${'%'}`}
                onChange={(_obj, val) => {
                  this.setState({
                    compensateValue: val,
                    numberOfTrees: Math.round(
                      (val / 100) * (emissionValue / this.co2pertree),
                    ),
                  });
                }}
              />
              <p className="value_label">
                <i>{numberOfTrees} trees</i>
              </p>
            </div>
          </CarbonBlockGrid>
        </div>
        {this.touchscreenButtonOutsideBlockMobile()}
      </CarbonTemplate>
    );
  };

  renderStartPage = () => {
    const { currentId, carbonItems } = this.state;
    return (
      <CarbonTemplate imageMobile={readyMobile} image={ready}>
        <Arrows
          currentId={currentId}
          carbonItemsLength={carbonItems.length}
          nextItem={this.nextItem}
        />

        <div className="carbon_item_container hor_center">
          {this.renderProgress()}
          <CarbonBlockGrid
            amountPages={this.amountPages}
            previousItem={() => this.previousItem()}
            nextItem={() => this.nextItem()}
            carbonItemsLength={carbonItems.length}
            currentId={currentId}
            page="start"
            label="Welcome!"
            text="Calculate your carbon impact"
            textSize={1}
          >
            <div id="proceedInfo">
              <i>READY?</i>
              <p>
                <i>slide to proceed</i>
              </p>
            </div>
            <div id="startPageSlider">
              <PrettoSlider
                className="Slider"
                aria-label="pretto slider"
                onChangeCommitted={(obj, val) => {
                  if (val === 100) {
                    setTimeout(() => {
                      this.nextItem(obj, val);
                    }, delay);
                  }
                }}
              />
            </div>

            <div>
              <img src={treemendoLogo} alt="logo" className="treemendo_logo" />
            </div>
          </CarbonBlockGrid>
        </div>
        {this.touchscreenButtonOutsideBlockMobile()}
      </CarbonTemplate>
    );
  };

  render() {
    const {
      currentId,
      carbonItems,
      numberOfTrees,
      compensateValue,
    } = this.state;
    if (!carbonItems) {
      // loading carbon items
      return null;
    }
    if (currentId === 0) return this.renderStartPage();

    if (currentId <= carbonItems.length) {
      return (
        <CarbonTemplate
          imageMobile={this.getCurrentItem().imageMobile}
          image={this.getCurrentItem().image}
        >
          <Arrows
            currentId={currentId}
            carbonItemsLength={carbonItems.length}
            nextItem={this.nextItem}
            prevItem={this.previousItem}
          />

          <div className="carbon_item_container hor_center">
            {this.renderProgress()}
            {this.renderItem()}
          </div>

          {this.touchscreenButtonOutsideBlockMobile()}
        </CarbonTemplate>
      );
    }

    if (currentId === carbonItems.length + 1) return this.renderCompensate();

    const commonProps = {
      currentId,
      amountTrees: numberOfTrees,
      percentage: compensateValue,
    };

    if (currentId === carbonItems.length + 2)
      return (
        <RegisterPage
          amountPages={this.amountPages}
          previousItem={() => this.previousItem()}
          nextItem={() => this.nextItem()}
          carbonItemsLength={carbonItems.length}
          {...commonProps}
          exchangeRate={this.exchangeRate}
          formatter={this.formatter}
          basePrice={this.basePrice}
        />
      );

    return (
      <ConfirmationPage
        amountPages={this.amountPages}
        previousItem={() => this.previousItem()}
        nextItem={() => this.nextItem()}
        carbonItemsLength={carbonItems.length}
        {...commonProps}
        amountCO2={this.emissionValue}
      />
    );
  }
}
export default CarbonCalculatorIndex;
