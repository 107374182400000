/* eslint-disable react/static-property-placement */
/* eslint-disable react/button-has-type */
import React from 'react';
import { MyContext } from '../Context';
import history from '../history';
import MiddleBlock from './components/MiddleBlock';

class Validated extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    this.state = {
      tree:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined &&
        context.state.plantType.name !== undefined
          ? context.state.plantType.name
          : 'tree',
      treeImage:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined &&
        context.state.plantType.photo !== undefined
          ? context.state.plantType.photo
          : '',
      description:
        context !== undefined &&
        context.state !== undefined &&
        context.state.plantType !== undefined &&
        context.state.plantType.description !== undefined
          ? context.state.plantType.description
          : 'Something went wrong, ga back and try again!',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to the top of the page
    if (this.context !== undefined) {
      const { state } = this.context;
      // go to errorPage if the context.state is undefined
      if (
        state !== undefined &&
        state.path !== undefined &&
        state.path !== '/code' &&
        state.path !== '/plant_on' &&
        !state.test
      ) {
        history.push('/code');
      } else if (
        state === undefined ||
        state.path === undefined ||
        state.plantType === undefined ||
        state.plantType.name === undefined ||
        state.plantType.photo === undefined ||
        state.plantType.description === undefined
      ) {
        history.push('/errorgc');
      }
    } else {
      // go to errorPage if context is undefined
      history.push('/errorgc');
    }
    if (document !== null && document.getElementById('plantmeButton') !== null)
      document.getElementById('plantmeButton').focus();
  }

  clickHandler = () => {
    if (this.context !== undefined) {
      const { setPath } = this.context;
      setPath('/validated');
      history.push('/plant');
      // Update context
    }
  };

  render() {
    const { tree, treeImage, description } = this.state;
    return (
      <MiddleBlock
        page="validated"
        title={`You received ${
          /[aeiouAEIOU]/.test(tree.charAt(0)) ? 'an' : 'a'
        } ${tree}`}
      >
        <p className="subtitle center_text">{description}</p>
        <img
          className="middle_block_img"
          id="validatedTreeImage"
          src={treeImage}
          alt="treeImage"
        />
        <div className="inner_block" id="validatedBlock">
          <p className="inner_block_title" id="innerValidatedTitle">
            Press here to plant the tree!
          </p>
          <button
            id="plantmeButton"
            className="button"
            onClick={this.clickHandler}
          >
            PLANT ME
          </button>
        </div>
      </MiddleBlock>
    );
  }
}

export default Validated;
