// make sure to import carbon calculator images here
import car from './Backgrounds/Desktop/car_back.png';
import electricity from './Backgrounds/Desktop/electricity_back.png';
import flight from './Backgrounds/Desktop/flight_back.png';
import food from './Backgrounds/Desktop/food_back.png';
import gas from './Backgrounds/Desktop/gas_back.png';
import publicTrans from './Backgrounds/Desktop/public_back.png';

import carMobile from './Backgrounds/Mobile/car_back_mobile.png';
import electricityMobile from './Backgrounds/Mobile/electricity_back_mobile.png';
import flightMobile from './Backgrounds/Mobile/flight_back_mobile.png';
import foodMobile from './Backgrounds/Mobile/food_back_mobile.png';
import gasMobile from './Backgrounds/Mobile/gas_back_mobile.png';
import publicTransMobile from './Backgrounds/Mobile/public_back_mobile.png';

import * as CountryFunctions from './countryData';

let curLocation = 'USA';

const getAverage = (questionId) => {
  switch (questionId) {
    case 1:
      return CountryFunctions.getHoursPublicTransport(curLocation);
    case 2:
      return CountryFunctions.getDistanceDriven(curLocation);
    case 3:
      return CountryFunctions.getHoursPlane(curLocation);
    case 4:
      return CountryFunctions.getPortionsMeatCheese(curLocation);
    case 5:
      return CountryFunctions.getMoneyElectricity(curLocation);
    case 6:
      return CountryFunctions.getMoneyGas(curLocation);
    default:
      return 0;
  }
};

const getAverageCO2 = (questionId) => {
  // No location as parameter indicates that the value is the same for each country (in this version)
  switch (questionId) {
    case 1:
      return CountryFunctions.getHoursPublicTransportCO2(curLocation);
    case 2:
      return CountryFunctions.getDistanceDrivenCO2(curLocation);
    case 3:
      return CountryFunctions.getHoursPlaneCO2();
    case 4:
      return CountryFunctions.getPortionsMeatCheeseCO2();
    case 5:
      return CountryFunctions.getMoneyElectricityCO2(curLocation);
    case 6:
      return CountryFunctions.getMoneyGasCO2(curLocation);
    default:
      return 0;
  }
};

const getDistanceSliderUnit = () => {
  switch (CountryFunctions.getDistanceUnit(curLocation)) {
    case 'kilometres':
      return 'km';
    case 'miles':
      return 'mi';
    default:
      return '';
  }
};

const getSliderData = (location) => {
  if (CountryFunctions.dataKnownOf(location)) curLocation = location;
  return [
    {
      id: 1,
      label: 'Public Transport',
      value: getAverage(1),
      average: getAverage(1),
      valueLabel: [
        'never',
        'barely',
        'occasional',
        'below average',
        'average',
        'above average',
        'often',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 40,
      exponent: 1,
      sliderUnit: 'hr',
      image: publicTrans,
      imageMobile: publicTransMobile,
      question: 'How many hours do you spend in public transport per week?',
      co2TransmissionPerUnit: getAverageCO2(1),
      duration: 'week', // choose between: "year"/"month"/"week"/"day"
      done: true,
    },
    {
      id: 2,
      label: 'Car',
      value: getAverage(2),
      average: getAverage(2),
      valueLabel: [
        "I don't drive",
        'barely',
        'occasional',
        'below average',
        'average',
        'above average',
        'often',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 1500,
      exponent: 1,
      sliderUnit: getDistanceSliderUnit(),
      image: car,
      imageMobile: carMobile,
      question: `How many ${CountryFunctions.getDistanceUnit(
        curLocation,
      )} do you drive per week?`,
      co2TransmissionPerUnit: getAverageCO2(2),
      duration: 'week', // choose between: "year"/"month"/"week"/"day"
      done: false,
    },
    {
      id: 3,
      label: 'Flight',
      value: getAverage(3),
      average: getAverage(3),
      valueLabel: [
        "I don't fly",
        'barely',
        'occasional',
        'below average',
        'average',
        'above average',
        'often',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 100,
      exponent: 1,
      sliderUnit: 'hr',
      image: flight,
      imageMobile: flightMobile,
      question: 'How many hours did you fly last year?',
      co2TransmissionPerUnit: getAverageCO2(3),
      duration: 'year', // choose between: "year"/"month"/"week"/"day"
      done: false,
    },
    {
      id: 4,
      label: 'Food',
      value: getAverage(4),
      average: getAverage(4),
      valueLabel: [
        'vegan',
        'vegetarian',
        'flexitarian',
        'below average',
        'average',
        'above average',
        'often',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 10,
      exponent: 1,
      sliderUnit: 'port.',
      image: food,
      imageMobile: foodMobile,
      question: 'How many portions of meat and/or cheese do you eat daily?',
      co2TransmissionPerUnit: getAverageCO2(4),
      duration: 'day', // choose between: "year"/"month"/"week"/"day"
      done: false,
    },
    {
      id: 5,
      label: 'Electricity',
      value: getAverage(5),
      average: getAverage(5),
      valueLabel: [
        'none',
        'barely',
        'economical',
        'below average',
        'average',
        'above average',
        'much',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 300,
      exponent: 1,
      sliderUnit: CountryFunctions.getCurrencyName(curLocation),
      image: electricity,
      imageMobile: electricityMobile,
      question: 'How much money do you spend on electricity every month?',
      co2TransmissionPerUnit: getAverageCO2(5),
      duration: 'month', // choose between: "year"/"month"/"week"/"day"
      done: false,
    },
    {
      id: 6,
      label: 'Gas',
      value: getAverage(6),
      average: getAverage(6),
      valueLabel: [
        'none',
        'barely',
        'economical',
        'below average',
        'average',
        'above average',
        'much',
        'double average',
        'triple average',
        'a lot',
      ],
      sliderMin: 0,
      sliderMax: 300,
      exponent: 1,
      sliderUnit: CountryFunctions.getCurrencyName(curLocation),
      image: gas,
      imageMobile: gasMobile,
      question: 'How much money do you spend on gas every month?',
      co2TransmissionPerUnit: getAverageCO2(6),
      duration: 'month', // choose between: "year"/"month"/"week"/"day"
      done: false,
    },
  ];
};

export default getSliderData;
