/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import './arrows.css';

// arrows
import rightArrow from '../Assets/arrowright.png';
import leftArrow from '../Assets/arrowleft.png';

const mobileFormat = 500;

class Arrows extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDesktopLeftArrow = () => {
    const { currentId, carbonItemsLength, prevItem } = this.props;
    if (
      window.innerWidth <= mobileFormat ||
      currentId > carbonItemsLength + 2 ||
      currentId === 0
    )
      return <div />;

    return (
      <div className="arrow" id="leftArrow" onClick={prevItem}>
        <img className="arrow" src={leftArrow} alt="LeftArrow" />
      </div>
    );
  };

  renderDesktopRightArrow = () => {
    const { currentId, carbonItemsLength, nextItem } = this.props;
    if (window.innerWidth <= mobileFormat || currentId >= carbonItemsLength + 2)
      return <div />;
    return (
      <div className="arrow" onClick={nextItem}>
        <img className="arrow" src={rightArrow} alt="RightArrow" />
      </div>
    );
  };

  render() {
    return (
      <div id="arrowGrid">
        {this.renderDesktopLeftArrow()}
        <div />
        {this.renderDesktopRightArrow()}
      </div>
    );
  }
}

export default Arrows;
