import React from 'react';
import { BrowserRouter as Route, Switch, Router } from 'react-router-dom';
import DeviceOrientation, { Orientation } from 'react-screen-orientation';
import PlantingPage from './GiftCode/Planting';
import CodePage from './GiftCode/Code';
import RegisterPage from './GiftCode/Register';
import ValidatedPage from './GiftCode/Validated';
import FinalPage from './GiftCode/Final';
import ErrorgcPage from './GiftCode/Errorgc';
import ErrorCarbonPage from './CarbonCalculator/ErrorCarbon';
import { MyProvider } from './Context';
import history from './history';
import './general.css';
import './appClass.css';
import CarbonCalculatorIndex from './CarbonCalculator/CarbonCalculatorIndex';

class AppInt extends React.Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.forceUpdate();
  };

  generalSwitch = () => {
    return (
      <div>
        <Switch>
          <Route exact path="/plant" component={PlantingPage}>
            <PlantingPage />
          </Route>
          <Route path="/register" component={RegisterPage}>
            <RegisterPage />
          </Route>
          <Route path="/code" component={CodePage}>
            <CodePage />
          </Route>
          <Route path="/validated" component={ValidatedPage}>
            <ValidatedPage />
          </Route>
          <Route path="/carbon" component={CarbonCalculatorIndex}>
            <CarbonCalculatorIndex />
          </Route>
          <Route path="/final" component={FinalPage}>
            <FinalPage />
          </Route>
          <Route path="/errorgc" component={ErrorgcPage}>
            <ErrorgcPage />
          </Route>
          <Route path="/errorcarbon" component={ErrorCarbonPage}>
            <ErrorCarbonPage />
          </Route>
          <Route exact path="" component={CodePage}>
            <CodePage />
          </Route>
        </Switch>
      </div>
    );
  };

  render() {
    if (window.innerWidth <= 450 || window.innerHeight <= 575) {
      return (
        <MyProvider>
          <Router history={history}>
            <DeviceOrientation lockOrientation="portrait">
              <Orientation orientation="portrait" alwaysRender={false}>
                {this.generalSwitch()}
              </Orientation>
              <Orientation orientation="landscape" alwaysRender={false}>
                <div>
                  <p id="rotateDevice">
                    Please rotate your device (mobile)
                    <br />
                    or increase your window size (desktop)
                  </p>
                </div>
              </Orientation>
            </DeviceOrientation>
          </Router>
        </MyProvider>
      );
    }

    return (
      <MyProvider>
        <Router history={history}>{this.generalSwitch()}</Router>
      </MyProvider>
    );
  }
}

export default AppInt;
