/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import './carbonBlockGrid.css';

const mobileFormat = 500;

class CarbonTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderLeftArrow = () => {
    const { currentId, carbonItemsLength, previousItem } = this.props;
    if (
      window.innerWidth > mobileFormat ||
      currentId > carbonItemsLength + 2 ||
      currentId === 0
    )
      return <div />;
    return (
      <div onClick={previousItem} className="pointer" id="leftPointer">
        {'<'}
      </div>
    );
  };

  renderRightArrow = () => {
    const { currentId, carbonItemsLength, nextItem } = this.props;

    if (window.innerWidth > mobileFormat || currentId >= carbonItemsLength + 2)
      return <div />;
    return (
      <div onClick={nextItem} className="pointer" id="rightPointer">
        {'>'}
      </div>
    );
  };

  touchscreenButtonInsideBlockMobile = () => {
    const { previousItem, nextItem } = this.props;
    if (window.innerWidth > mobileFormat) return <div />;
    return (
      <div className="touch_block_container">
        <div onClick={previousItem} className="inner_touch_block" />
        <div onClick={nextItem} className="inner_touch_block" />
      </div>
    );
  };

  renderText = () => {
    const { text, textSize } = this.props;
    if (text !== undefined)
      return (
        <p className="item_text" style={{ fontSize: `${textSize}em` }}>
          {text}
        </p>
      );
    return null;
  };

  render() {
    const { children, page, label, currentId, carbonItemsLength } = this.props;
    return (
      <div className="carbon_block_grid" id={`${page}PageGrid`}>
        <div className="carbon_block_title">
          {this.renderLeftArrow()}
          <div className="item_label">{label}</div>
          {this.renderRightArrow()}
        </div>
        {this.renderText()}
        {children}
        {currentId < carbonItemsLength + 2
          ? this.touchscreenButtonInsideBlockMobile()
          : null}
      </div>
    );
  }
}

export default CarbonTemplate;
