/* eslint-disable react/button-has-type */
import React from 'react';
import history from '../history';
import facebookImg from '../pictures/fbook.png';
import mailImg from '../pictures/mailBtn.png';
import whatsappImg from '../pictures/WhatsApp.png';
import errorBack from './Backgrounds/Desktop/error.png';
import errorBackMobile from './Backgrounds/Mobile/error_mobile.png';
import CarbonTemplate from './components/CarbonTemplate';
import './errorCarbon.css';

class ErrorCarbon extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      image: errorBack,
      imageMobile: errorBackMobile,
      error:
        context !== undefined &&
        context.state !== undefined &&
        context.state.error !== undefined
          ? context.state.error
          : 'Something seems broken in our context',
    };
  }

  clickHandlerStart = () => {
    const { setError } = this.context;
    setError('Something seems broken in our context');
    history.push('/carbon');
  };

  clickHandlerFacebook = () => {
    window.open('http://m.me/treemendo.forests ');
  };

  clickHandlerWhatsapp = () => {
    window.open('https://wa.me/447308950978');
  };

  clickHandlerEmail = () => {
    window.open('mailto:support@treemendo.com');
  };

  render() {
    const { error, imageMobile, image } = this.state;
    return (
      <CarbonTemplate imageMobile={imageMobile} image={image}>
        <div className="carbon_item_container_error hor_center">
          <div />
          <div>
            <p id="lostText">
              Damn! We might have lost you somewhere between the trees.
            </p>
            <p id="errorMessagecarbon" className="subtitle center_text">
              {error}
            </p>
            <button
              id="errorcarbonButton"
              className="button"
              onClick={this.clickHandlerStart}
            >
              RETRY?
            </button>
            <form>
              <button
                id="errorcarbonButton"
                className="button"
                formAction="https://treemendo.com/"
              >
                TREEMENDO.COM
              </button>
            </form>
            <p className="subtitle center_text italic_text" id="helpText">
              Still not working? Ping us up! <br />
              We are here to help!
            </p>
            <div id="helpButtonsBlockcarbon">
              <input
                type="image"
                id="facebookHelpcarbon"
                alt="Contact us on Facebook"
                src={facebookImg}
                onClick={this.clickHandlerFacebook}
              />
              <input
                type="image"
                id="whatsappHelpcarbon"
                alt="Contact us via Whatsapp"
                src={whatsappImg}
                onClick={this.clickHandlerWhatsapp}
              />
              <input
                type="image"
                id="emailHelpcarbon"
                alt="Contact us via E-mail"
                src={mailImg}
                onClick={this.clickHandlerEmail}
              />
            </div>
          </div>
        </div>
      </CarbonTemplate>
    );
  }
}

export default ErrorCarbon;
