/* eslint-disable react/static-property-placement */
/* eslint-disable react/button-has-type */
import React from 'react';
import { MyContext } from '../Context';
import history from '../history';
import MiddleBlock from './components/MiddleBlock';
import facebookImg from '../pictures/fbook.png';
import mailImg from '../pictures/mailBtn.png';
import whatsappImg from '../pictures/WhatsApp.png';

class Errorgc extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props, context) {
    super(props);
    this.state = {
      error:
        context !== undefined &&
        context.state !== undefined &&
        context.state.error !== undefined
          ? context.state.error
          : 'Something seems broken in our context',
    };
  }

  clickHandlerStart = () => {
    const { setError } = this.context;
    setError('Something seems broken in our context');
    history.push('/code');
  };

  clickHandlerFacebook = () => {
    window.open('http://m.me/treemendo.forests ');
  };

  clickHandlerWhatsapp = () => {
    window.open('https://wa.me/447308950978');
  };

  clickHandlerEmail = () => {
    window.open('mailto:support@treemendo.com');
  };

  render() {
    const { error } = this.state;
    return (
      <MiddleBlock
        page="errorgc"
        title="Damn! We might have lost you somewhere between the trees"
      >
        <p id="errorMessagegc" className="subtitle center_text">
          {error}
        </p>
        <button
          id="errorgcButton"
          className="button"
          onClick={this.clickHandlerStart}
        >
          RETRY?
        </button>
        <form>
          <button
            id="errorgcButton"
            className="button"
            formAction="https://treemendo.com/"
          >
            TREEMENDO.COM
          </button>
        </form>
        <p className="subtitle center_text italic_text" id="helpText">
          Still not working? Ping us up! <br />
          We are here to help!
        </p>
        <div id="helpButtonsBlockgc">
          <input
            type="image"
            id="facebookHelpgc"
            alt="Contact us on Facebook"
            src={facebookImg}
            onClick={this.clickHandlerFacebook}
          />
          <input
            type="image"
            id="whatsappHelpgc"
            alt="Contact us via Whatsapp"
            src={whatsappImg}
            onClick={this.clickHandlerWhatsapp}
          />
          <input
            type="image"
            id="emailHelpgc"
            alt="Contact us via E-mail"
            src={mailImg}
            onClick={this.clickHandlerEmail}
          />
        </div>
      </MiddleBlock>
    );
  }
}

export default Errorgc;
