/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-cond-assign */
/* eslint-disable react/static-property-placement */
import React from 'react';
import { MyContext } from '../Context';
import history from '../history';
import giftImage from '../pictures/gift.png';
import MiddleBlock from './components/MiddleBlock';
import { showAGift } from '../connectors/treemendo';

class Code extends React.Component {
  // Taking the context from the Context.js, we can use now "this.context.state...." or "this.context.FUNCTION"
  static contextType = MyContext;

  constructor(props) {
    super(props);
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const urlGiftCode = params.get('gc');
    let codeParts = [];
    this.maxLength = 4;

    if (urlGiftCode)
      // Extract giftcode from URL params
      codeParts = this.splitCodeString(urlGiftCode);
    else codeParts = ['', '', '', ''];

    this.state = {
      verificationCodeField1: codeParts[0],
      verificationCodeField2: codeParts[1],
      verificationCodeField3: codeParts[2],
      verificationCodeField4: codeParts[3],
      giftCodeValidationError: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to the top of the page
    if (this.context !== undefined) {
      const {
        state,
        setGiftCode,
        setPath,
        setTree,
        setLocation,
        setUserData,
        setForestId,
        setError,
      } = this.context;
      if (state === undefined) {
        history.push('/code');
      } else {
        setGiftCode('');
        setPath('');
        setTree('');
        setLocation('');
        setUserData('');
        setForestId(0);
        setError('Something seems broken in our context');
      }
    }
    if (
      document !== null &&
      document.getElementById('verificationCodeField1') !== null
    )
      document.getElementById('verificationCodeField1').focus();
  }

  splitCodeString = (string) => [
    string.slice(0, this.maxLength),
    string.slice(this.maxLength, 2 * this.maxLength),
    string.slice(2 * this.maxLength, 3 * this.maxLength),
    string.slice(3 * this.maxLength, 4 * this.maxLength),
  ];

  mergeCodeString = (part1, part2, part3, part4) =>
    `${part1}${part2}${part3}${part4}`;

  onKeyUp = (e) => {
    const target = e.srcElement || e.target;
    const myLength = target.value.length;
    if (myLength >= this.maxLength) {
      let next = target;
      while ((next = next.nextElementSibling) != null) {
        if (next == null) break;
        if (next.tagName.toLowerCase() === 'input') {
          next.focus();
          break;
        }
      }
    }
    // Move to previous field if empty (user pressed backspace)
    else if (myLength === 0) {
      let previous = target;
      while ((previous = previous.previousElementSibling) != null) {
        if (previous == null) break;
        if (previous.tagName.toLowerCase() === 'input') {
          previous.focus();
          break;
        }
      }
    }
  };

  isCodeFullyFilledIn = () => {
    const {
      verificationCodeField1,
      verificationCodeField2,
      verificationCodeField3,
      verificationCodeField4,
    } = this.state;
    if (
      verificationCodeField1.length === 4 &&
      verificationCodeField2.length === 4 &&
      verificationCodeField3.length === 4 &&
      verificationCodeField4.length === 4
    ) {
      return false;
    }
    return true;
  };

  checkCode = () => {
    const {
      verificationCodeField1,
      verificationCodeField2,
      verificationCodeField3,
      verificationCodeField4,
    } = this.state;
    const giftCode = this.mergeCodeString(
      verificationCodeField1,
      verificationCodeField2,
      verificationCodeField3,
      verificationCodeField4,
    );
    const { setTree, state, setGiftCode, setPath, setForestId } = this.context;

    showAGift(giftCode)
      .then((resolve) => {
        if (!resolve.data.used) {
          setTree(resolve.data.plant_type);
          state.giftCode = giftCode;
          setGiftCode(giftCode);
          setForestId(resolve.data.forest.forest_id);
          setPath('/code');
          history.push('/validated');
        } else {
          this.setState({ giftCodeValidationError: true });
        }
      })
      .catch((error) => {
        if (
          error !== undefined &&
          error.response !== undefined &&
          error.response.status === 404
        ) {
          this.setState({ giftCodeValidationError: true });
        } else {
          const { setError } = this.context;
          setError('Something seems to be wrong in our database');
          history.push('/errorgc');
        }
      });
  };

  giftCodeValidationError = () => {
    const { giftCodeValidationError } = this.state;
    if (giftCodeValidationError) {
      return (
        <p className="subtitle error">
          This code is not valid, please make sure you entered the code
          correctly.
        </p>
      );
    }
    return null;
  };

  handleChange = (event) => {
    const { name } = event.target;
    this.setState({
      [name]: event.target.value,
    });
  };

  renderVerCodeField = (number) => {
    return (
      <input
        name={`verificationCodeField${number}`}
        id={`verificationCodeField${number}`}
        type="text"
        maxLength={this.maxLength}
        required
        pattern="[A-Za-z0-9]{4}"
        placeholder="_ _ _ _"
        // eslint-disable-next-line react/destructuring-assignment
        value={this.state[`verificationCodeField${number}`]}
        onChange={this.handleChange}
      />
    );
  };

  render() {
    return (
      <MiddleBlock page="code" title="Activate your digital seed">
        <p className="subtitle center_text">
          Receive a tree seed code from friends or when you purchase a product
          of one of our partners.
        </p>
        <img
          className="middle_block_img"
          id="giftImage"
          src={giftImage}
          alt="giftImage"
        />

        <div className="inner_block" id="codeBlock">
          <p className="inner_block_title">Enter your code below</p>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              this.checkCode();
            }}
          >
            <div onKeyUp={this.onKeyUp} id="giftCodeForm">
              {this.renderVerCodeField(1)}
              {this.renderVerCodeField(2)}
              {this.renderVerCodeField(3)}
              {this.renderVerCodeField(4)}
            </div>
            <br />
            {this.giftCodeValidationError()}
            <button
              type="submit"
              className="button"
              data-testid="button1"
              disabled={this.isCodeFullyFilledIn()}
            >
              ACTIVATE SEED
            </button>
          </form>
        </div>

        <p className="extra_info center_text" id="getCodeStatement">
          Don&apos;t have a code yet? <br />
          Get yours{' '}
          <a
            href="https://treemendo.com/product/your-first-tree/"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </MiddleBlock>
    );
  }
}

export default Code;
