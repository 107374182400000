import React, { Component } from 'react';
import './agreeInfo.css';

class AgreeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <p className="carbon_agree_info">
        You will receive a confirmation and certificate of your compensation by
        email. By confirming you agree to the Treemendo Ltd{' '}
        <a
          href="https://www.treemendo.com/terms-of-service/"
          target="_blank"
          rel="noopener noreferrer"
          className="white"
        >
          Terms and Conditions
        </a>{' '}
        and{' '}
        <a
          href="https://www.treemendo.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
          className="white"
        >
          Privacy Policy
        </a>
        .
      </p>
    );
  }
}

export default AgreeInfo;
